import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as indexzjaNqSEaloMeta } from "/opt/build/repo/pages/booking/add-on/index.vue?macro=true";
import { default as _91attributeUuid_93JkdyEfddUBMeta } from "/opt/build/repo/pages/booking/attribute/[attributeUuid].vue?macro=true";
import { default as basketuAF7jOR4yUMeta } from "/opt/build/repo/pages/booking/basket.vue?macro=true";
import { default as _91_91categoryUuid_93_93IFKN4S7EudMeta } from "/opt/build/repo/pages/booking/category/[[categoryUuid]].vue?macro=true";
import { default as checkoutZdnSZSWP2SMeta } from "/opt/build/repo/pages/booking/checkout.vue?macro=true";
import { default as confirmationF4sNVQClRwMeta } from "/opt/build/repo/pages/booking/confirmation.vue?macro=true";
import { default as end_45dateQDp4Nm2NzXMeta } from "/opt/build/repo/pages/booking/end-date.vue?macro=true";
import { default as _91categoryUuid_93TsOfBrEKmkMeta } from "/opt/build/repo/pages/booking/equipment-type/[categoryUuid].vue?macro=true";
import { default as locationSRRwl3dT3aMeta } from "/opt/build/repo/pages/booking/location.vue?macro=true";
import { default as handlerVKuyxUG6vZMeta } from "/opt/build/repo/pages/booking/payment/handler.vue?macro=true";
import { default as indexT5luUSProvMeta } from "/opt/build/repo/pages/booking/payment/index.vue?macro=true";
import { default as _91orderReference_93ANNDcum6L0Meta } from "/opt/build/repo/pages/booking/regenerate/[orderReference].vue?macro=true";
import { default as start_45datewkjdeSTTbGMeta } from "/opt/build/repo/pages/booking/start-date.vue?macro=true";
import { default as contact_45usM8CpKpYLVzMeta } from "/opt/build/repo/pages/contact-us.vue?macro=true";
import { default as _91equipmentTypeSlug_93YSNFqb7rtSMeta } from "/opt/build/repo/pages/equipment/[equipmentTypeSlug].vue?macro=true";
import { default as _91attributeValueSlug_934MubDlmLlUMeta } from "/opt/build/repo/pages/equipment/attributes/[categorySlug]/[attributeSlug]/[attributeValueSlug].vue?macro=true";
import { default as _91_46_46_46categorySlug_93cYrRQsjETLMeta } from "/opt/build/repo/pages/equipment/categories/[...categorySlug].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91articleSlug_93abgr3m7ko9Meta } from "/opt/build/repo/pages/knowledge/[articleSlug].vue?macro=true";
import { default as _91_46_46_46slug_93zjsHwrE3mBMeta } from "/opt/build/repo/pages/knowledge/c/[...slug].vue?macro=true";
import { default as _91townCode_93mt9p9VViD5Meta } from "/opt/build/repo/pages/locations/category/[categorySlug]/[countyCode]/[townCode].vue?macro=true";
import { default as index8qpJy8byvzMeta } from "/opt/build/repo/pages/locations/category/[categorySlug]/[countyCode]/index.vue?macro=true";
import { default as my_45bookinghptbBivJSvMeta } from "/opt/build/repo/pages/my-booking.vue?macro=true";
import { default as _91_46_46_46slug_93JqrI17zkodMeta } from "/opt/build/repo/pages/pl/[...slug].vue?macro=true";
import { default as _91email_93gYDxd8wzsUMeta } from "/opt/build/repo/pages/supplier/manage-booking/[orderReference]/[email].vue?macro=true";
import { default as component_45stubRU5sOBTecHMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubRU5sOBTecH } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/[...slug].vue")
  },
  {
    name: "booking-add-on",
    path: "/booking/add-on",
    meta: indexzjaNqSEaloMeta || {},
    component: () => import("/opt/build/repo/pages/booking/add-on/index.vue")
  },
  {
    name: "booking-attribute-attributeUuid",
    path: "/booking/attribute/:attributeUuid()",
    meta: _91attributeUuid_93JkdyEfddUBMeta || {},
    component: () => import("/opt/build/repo/pages/booking/attribute/[attributeUuid].vue")
  },
  {
    name: "booking-basket",
    path: "/booking/basket",
    meta: basketuAF7jOR4yUMeta || {},
    component: () => import("/opt/build/repo/pages/booking/basket.vue")
  },
  {
    name: "booking-category-categoryUuid",
    path: "/booking/category/:categoryUuid?",
    meta: _91_91categoryUuid_93_93IFKN4S7EudMeta || {},
    component: () => import("/opt/build/repo/pages/booking/category/[[categoryUuid]].vue")
  },
  {
    name: "booking-checkout",
    path: "/booking/checkout",
    meta: checkoutZdnSZSWP2SMeta || {},
    component: () => import("/opt/build/repo/pages/booking/checkout.vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking/confirmation",
    meta: confirmationF4sNVQClRwMeta || {},
    component: () => import("/opt/build/repo/pages/booking/confirmation.vue")
  },
  {
    name: "booking-end-date",
    path: "/booking/end-date",
    meta: end_45dateQDp4Nm2NzXMeta || {},
    component: () => import("/opt/build/repo/pages/booking/end-date.vue")
  },
  {
    name: "booking-equipment-type-categoryUuid",
    path: "/booking/equipment-type/:categoryUuid()",
    meta: _91categoryUuid_93TsOfBrEKmkMeta || {},
    component: () => import("/opt/build/repo/pages/booking/equipment-type/[categoryUuid].vue")
  },
  {
    name: "booking-location",
    path: "/booking/location",
    meta: locationSRRwl3dT3aMeta || {},
    component: () => import("/opt/build/repo/pages/booking/location.vue")
  },
  {
    name: "booking-payment-handler",
    path: "/booking/payment/handler",
    meta: handlerVKuyxUG6vZMeta || {},
    component: () => import("/opt/build/repo/pages/booking/payment/handler.vue")
  },
  {
    name: "booking-payment",
    path: "/booking/payment",
    meta: indexT5luUSProvMeta || {},
    component: () => import("/opt/build/repo/pages/booking/payment/index.vue")
  },
  {
    name: "booking-regenerate-orderReference",
    path: "/booking/regenerate/:orderReference()",
    meta: _91orderReference_93ANNDcum6L0Meta || {},
    component: () => import("/opt/build/repo/pages/booking/regenerate/[orderReference].vue")
  },
  {
    name: "booking-start-date",
    path: "/booking/start-date",
    meta: start_45datewkjdeSTTbGMeta || {},
    component: () => import("/opt/build/repo/pages/booking/start-date.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/opt/build/repo/pages/contact-us.vue")
  },
  {
    name: "equipment-equipmentTypeSlug",
    path: "/equipment/:equipmentTypeSlug()",
    component: () => import("/opt/build/repo/pages/equipment/[equipmentTypeSlug].vue")
  },
  {
    name: "equipment-attributes-categorySlug-attributeSlug-attributeValueSlug",
    path: "/equipment/attributes/:categorySlug()/:attributeSlug()/:attributeValueSlug()",
    component: () => import("/opt/build/repo/pages/equipment/attributes/[categorySlug]/[attributeSlug]/[attributeValueSlug].vue")
  },
  {
    name: "equipment-categories-categorySlug",
    path: "/equipment/categories/:categorySlug(.*)*",
    component: () => import("/opt/build/repo/pages/equipment/categories/[...categorySlug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "knowledge-articleSlug",
    path: "/knowledge/:articleSlug()",
    component: () => import("/opt/build/repo/pages/knowledge/[articleSlug].vue")
  },
  {
    name: "knowledge-c-slug",
    path: "/knowledge/c/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/knowledge/c/[...slug].vue")
  },
  {
    name: "locations-category-categorySlug-countyCode-townCode",
    path: "/locations/category/:categorySlug()/:countyCode()/:townCode()",
    component: () => import("/opt/build/repo/pages/locations/category/[categorySlug]/[countyCode]/[townCode].vue")
  },
  {
    name: "locations-category-categorySlug-countyCode",
    path: "/locations/category/:categorySlug()/:countyCode()",
    component: () => import("/opt/build/repo/pages/locations/category/[categorySlug]/[countyCode]/index.vue")
  },
  {
    name: "my-booking",
    path: "/my-booking",
    component: () => import("/opt/build/repo/pages/my-booking.vue")
  },
  {
    name: "pl-slug",
    path: "/pl/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/pl/[...slug].vue")
  },
  {
    name: "supplier-manage-booking-orderReference-email",
    path: "/supplier/manage-booking/:orderReference()/:email()",
    component: () => import("/opt/build/repo/pages/supplier/manage-booking/[orderReference]/[email].vue")
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/pl/skip-hire-essex",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/pl/skip-hire-london",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/pl/skip-hire-bristol",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/surrey/weybridge",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/pl/skip-hire-gloucester",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/pl/skip-hire-derby",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/pl/skip-hire-doncaster",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/pl/skip-hire-stockport",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/pl/skip-hire-stoke-on-trent",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/pl/skip-hire-bradford",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/pl/skip-hire-birmingham",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/pl/skip-hire-sheffield",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/pl/skip-hire-manchester",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/pl/skip-hire-glasgow",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/2-yard-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/4-yard-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/6-yard-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/8-yard-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/10-yard-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/12-yard-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/14-yard-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/16-yard-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/knowledge/do-you-need-insurance-to-hire-a-mini-digger-",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/categories/skips",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/categories/waste/skips",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/knowledge/what-can-I-put-into-a-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/knowledge/what-can-i-put-into-a-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/bristol",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-manchester/stockport",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/bristol/bristol",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/gloucestershire/lydney",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/gloucestershire",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/gloucestershire/gloucester",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/wiltshire/swindon",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/nottinghamshire/nottingham",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/derbyshire/derby",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/leicestershire",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/leicestershire/leicester",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/lincolnshire/lincoln",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/lincolnshire",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/northamptonshire",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/northamptonshire/northampton",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/south-yorkshire/sheffield",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/west-yorkshire/leeds",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/west-yorkshire/wakefield",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/south-yorkshire/rotherham",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/south-yorkshire/barnsley",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/west-yorkshire/bradford",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/south-yorkshire/doncaster",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/west-yorkshire/huddersfield",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/east-riding-of-yorkshire/kingston-upon-hull",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/north-yorkshire/scarborough",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/west-yorkshire/keighley",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/west-yorkshire/pontefract",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/north-yorkshire/york",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/west-midlands/birmingham",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/west-midlands/solihull",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/essex/clacton-on-sea",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/essex",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/city-of-glasgow",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/city-of-glasgow/glasgow",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-manchester",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-manchester/manchester",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/merseyside/liverpool",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/worcestershire/redditch",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/staffordshire/stoke-on-trent",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/west-midlands/coventry",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/west-midlands/wolverhampton",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/merseyside",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/enfield",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/wandsworth",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/pinner",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/putney",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/willesden",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/harrow",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/hendon",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/wood-green",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/edgware",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/northwood",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/category/skips/greater-london/southwark",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/crayford",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/richmond",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/eltham",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/fulham",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/merton",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/barnes",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/hayes",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/bermondsey",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/walthamstow",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/upminster",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/east-ham",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/woodford",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/stratford",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/uxbridge",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/hammersmith",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/chiswick",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/westminster",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-london/croydon",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/staffordshire/tamworth",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/west-midlands/oldbury",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/herefordshire/hereford",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/warwickshire/warwick",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/west-midlands/walsall",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/warwickshire/rugby",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/lancashire/blackpool",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-manchester/bolton",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/lancashire/preston",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/greater-manchester/wigan",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/cheshire/warrington",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/lancashire/great-harwood",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/lancashire/leyland",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/gwent/caerphilly-caerffili",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/south-glamorgan/cardiff-caerdydd",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/dyfed/newport-trefdraeth",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/city-of-edinburgh",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/fife",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/perth-and-kinross",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/perth-and-kinross/perth",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/kent",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/west-glamorgan/swansea-abertawe",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/gloucestershire/cheltenham",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/ayrshire-and-arran/kilmarnock",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/city-of-dundee/dundee",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/west-lothian",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/clackmannan/alloa",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/stirling-and-falkirk/falkirk",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/hampshire/southampton",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/buckinghamshire/milton-keynes",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/hampshire/basingstoke",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/berkshire/slough",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/essex/colchester",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/norfolk/norwich",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/hertfordshire/stevenage",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/cambridgeshire/cambridge",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/norfolk/kings-lynn",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/bedfordshire/luton",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/essex/brentwood",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/essex/braintree",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/hertfordshire",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/essex/chelmsford",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/lincolnshire/scunthorpe",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/suffolk/ipswich",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/north-yorkshire/middlesbrough",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/tyne-and-wear/newcastle-upon-tyne",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/durham/stockton-on-tees",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/essex/harlow",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/locations/category/skips/norfolk",
    component: component_45stubRU5sOBTecH
  }
]